<template>
  <!-- 明細 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            下单明细
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox">
          <div class="itemTime">订单明细</div>
          <div style="margin-bottom:10px; display:flex;font-size:14px;">
            <div><span style="color:#898989;">供应商/采购员：</span>{{currRow.bind_name || '--'}}</div>
            <div style="margin-left:50px;"><span style="color:#898989;">标准名称：</span>{{currRow.good_name || '--'}}</div>
          </div>
          <el-table
            :data="table.data"
            style="width: 100%"
            :row-key="row => row.id"
            @selection-change="handleSelectionChange"
            border>
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column property="norm_name" label="商品名称" width="150"></el-table-column>
            <el-table-column property="store_name" label="数据来源" width="150">
              <template slot-scope="{row}">
                <span v-if="!!row.store_name">{{row.store_name}}</span>
                <span v-else>{{row.source == 1 ? '销售订单' : '平台自采'}}</span>
              </template>
            </el-table-column>
            <!-- 待确认的展示订单编号 -->
            <el-table-column property="order_no" label="订单编号" width="160" >
              <template slot-scope="{row}">
                <el-link type="primary" @click="showDetailOrder(row)">{{row.order_no}}</el-link>
              </template>
            </el-table-column>
            <!-- status：待确认状态可以改供应商/采购员，已采购状态不显示 -->
            <el-table-column
              v-if="sleDate.status == 1"
              prop="supplier_name"
              label="供应商/采购员"
              width="120px">
              <template slot-scope="{row,$index}">
                <el-popover
                  :ref="`popoverDom+${$index}`"
                  placement="bottom"
                  width="300"
                  trigger="click">
                  <div>供应商/采购员</div>
                  <el-select v-model="person_obj" filterable style="width:100%;margin:10px 0;" @focus="getSupplier" value-key="id">
                    <el-option v-for="item in supplierArr" :label="item.name" :value="item" :key="item.id">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.type == 1 ? '供应商' : '采购员' }}</span>
                    </el-option>
                  </el-select>
                  <el-row type="flex" justify="center">
                    <el-button type="primary" @click="handleRemark(row,$index)">确定</el-button>
                  </el-row>
                  <el-button slot="reference" type="text"  style="color:#409eff;width:98px;text-align: left;">
                    <span v-if="!!row.supplier_name" style="width:98px;display: inline-block;overflow: hidden;">{{row.supplier_name}}</span>
                    <i v-else class="el-icon-edit"></i>
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column property="num" label="下单数量">
              <template slot-scope="{row}">
                <span>{{row.num}}{{currRow.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column property="use_inventory" label="使用库存">
              <template slot-scope="{row}">
                <span>{{row.use_inventory}}</span><span v-if="row.use_inventory > 0">{{row.unit_name}}</span>
              </template>
            </el-table-column>
            
            <el-table-column property="loss_num" label="计算损耗数量" v-if="currRow.selStatus == 3 || (currRow.selStatus ==1 && !!currRow.is_loss)">
              <template slot-scope="{row}">
                <span>{{row.loss_num}}</span>
              </template>
            </el-table-column>
            <el-table-column property="purchase_num" label="需采购数量" width="150">
              <template slot-scope="{row}">
                <!-- status：待确认状态可以修改需采购数量，已采购状态不可修改 -->
                <div style="display:flex;align-items: center;" v-if="sleDate.status == 1">
                  <el-input v-model="row.purchase_num" placeholder="请输入" size="small" @input="row.purchase_num=row.purchase_num.match(/\d+(\.\d{0,2})?/) ? row.purchase_num.match(/\d+(\.\d{0,2})?/)[0] : ''">
                  </el-input>
                  <span style="margin-left:5px;width:60px;">{{row.unit_name}}</span>
                </div>
                <span v-if="sleDate.status == 3 && !!row.purchase_num">{{row.purchase_num}}{{row.unit_name}}</span>
              </template>
            </el-table-column>
            <el-table-column property="create_time" label="创建时间" width="140"></el-table-column>
            <el-table-column property="remark" label="备注" width="160"></el-table-column>
            <el-table-column property="update_time" label="更新时间" width="160"></el-table-column>
          </el-table>

          <div class="pageWrap" v-if="table.total > 10">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="table.params.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="table.params.count"
              layout="total, sizes, prev, pager, next, jumper"
              :total="table.total">
            </el-pagination>
          </div>

        </div>
        <!-- 待确认状态才有已下操作功能 -->
        <div class="footerWrap" v-if="sleDate.status == 1">
          <el-button type="primary" @click="handleCancel">取消采购</el-button>
          <el-button type="primary" @click="handleConfirm">保存</el-button>
        </div>
      </div>
    </div>
    <!-- 订单详情 -->
    <OrderDetail ref="orderdetail" ></OrderDetail>
    <!-- 采购单详情 -->
    <PurchaseDetail ref="purchasedetail" ></PurchaseDetail>

  </div>
</template>

<script>
  import OrderDetail from '@/layout/OrderDetail.vue'
  import PurchaseDetail from '@/views/purchase/list/components/Details.vue'
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'GatherDetail',
    props:['sleDate'],
    mixins: [mixinTable],
    components: {
      OrderDetail,
      PurchaseDetail
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        multipleSelection: [],
        table: {
          params:{
            page:1,
            count:10,
          },
          total: 0,
          data:[],
        },
        cateOpt: [],
        isSelShow: true,
        currRow: {},
        supplierArr: [], // 供应商/采购员
        person_obj: {}, // 供应商/采购员参数
        is_self: 1,// 类型 1:订单详情 2:自采详情
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        // this.is_self = type; 
        this.getTable()
      },
      reset(done) {
        this.isChange = false
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 单品汇总详情
      getTable() {
        let _params = {
          ...this.table.params,
          goods_name: this.currRow.good_name, // 商品名称
          unit_name: this.currRow.unit_name, //单位
          uni_spec: this.currRow.uni_spec, //区分标识
          supplier_id: this.currRow.bind_id == 0 ? '-1' : this.currRow.bind_id , //供应商/采购员ID(如果没有采购员或供应商时传-1)
          address_id: this.sleDate.address_id, //门店ID
          order_cate_id: this.sleDate.order_cate_id, //下单分类ID
          order_cate_tow: this.sleDate.order_cate_tow, //二级下单分类ID
          start: !!this.sleDate.orderDate ? this.sleDate.orderDate : '', // 下单日期
          end: !!this.sleDate.orderDate ? this.sleDate.orderDate : '', // 下单日期
          distr_start: !!this.sleDate.distr ? this.sleDate.distr : '',  // 配送日期
          distr_end: !!this.sleDate.distr ? this.sleDate.distr : '', // 配送日期
          status: this.sleDate.status, // 状态 1:待确认 2:已确认
          is_self: 1, // 类型 1:订单详情 2:自采详情
          is_loss: (this.currRow.is_loss == true && this.currRow.selStatus == 1) ? 1 : 0, 
        }
        this.$http.get("/admin/purchase_order/goodsInfo",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list
            this.table.total = res.data.total;
          }
        })
      },
      // 订单详情
      showDetailOrder(row) {
        let dom = this.$refs.orderdetail;
        dom.toggle(true);
        dom.getDetail(row.order_id);
        dom = null;
      },
      // 采购单详情
      showDetail(row) {
        let dom = this.$refs.purchasedetail;
        let obj = {
          id: row.purchase_id, //采购单id
          showType: 9999, // 公用组件，不展示底部操作按钮
        }
        dom.toggle(true);
        dom.getDetail(obj);
        dom = null;
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
        this.table.data = [];
      },
      // 批量选择
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 获取供应商/采购员(status:1启用中的供应商)
      getSupplier() {
        this.$http.get('/admin/supplier/allList',{params:{status:1,page:1, count:10000}}).then(res => {
          if(res.code == 1) {
            this.supplierArr = res.data.list;
          }
        })
      },
      // 修改供应商/采购员
      handleRemark(row,index) {
        if(!this.person_obj.id) {
          this.$message.warning("请选择供应商/采购员")
          return
        }
        this.$confirm('请确认是否修改？','提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let _params = {
            // details: [
            //   {
            //     detail_id: row.id,
            //     supplier_id: this.person_obj.id,
            //   }
            // ]
            supplier_id: this.person_obj.id, // 供应商/采购员id
            details: row.id, //订单明细Id 
            type: 1 //类型 1:待确认 
          }
          this.$refs[`popoverDom+${index}`].doClose()
          this.table.data[index].bind_id = this.person_obj.id;
          this.table.data[index].supplier_name = this.person_obj.name;
          document.body.click();
          this.person_obj = {};
          
          this.$http.post('/admin/order/updateBuyer', _params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.$refs[`popoverDom+${index}`].doClose()
              this.table.data[index].bind_id = this.person_obj.id;
              this.table.data[index].supplier_name = this.person_obj.name;
              document.body.click();
              this.person_obj = {};
              this.getTable();
            }
          })
        }).catch(() =>{}) 
      },
      // 取消采购
      handleCancel() {
        if(this.multipleSelection.length > 0) {
          let _ids = this.multipleSelection.map(v => {
            return v.id
          }).join("|");
          let _params = {
            ids: _ids
          }
          this.$confirm('请确认取消采购？','提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$http.post('/admin/purchase_order/cancelPurchase', _params).then(res => {
              if(res.code == 1) {
                this.$message.success("操作成功！")
                this.toggle(false)
                this.$emit('refresh')
              }
            })
          }).catch(() => {})
        } else {
          this.$message.warning("请至少选择一条数据")
        }
      },
      // 需采购数量修改保存
      handleConfirm() {
        let _params = {
          details: this.table.data.map(v => {
            return {
              detail_id: v.id,
              purchase_num: v.purchase_num,
            }
          })
        }
        this.$http.post('/admin/order/updatePurchase', _params).then(res => {
          if(res.code == 1) {
            this.$message.success("操作成功！")
            this.getTable();
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}
</style>
